import {query_rule} from "../api";

class Ability{
  constructor(type, name, contidion="", description="") {
    this.id = name.toLowerCase().replace(' ', '_')
    this.type = type
    this.name = name
    this.condition = contidion
    this.description = description
  }

  set_parameter(parameter){
    const copy =  { ...this }
    copy.parameter = parameter
    return copy
  }
}



export const lead_the_righteous = new Ability("UNIT", "Lead the Righteous",
  "Пока эта модель возглавляет юнит.",
  "Каждый раз, когда модель в этом юните совершает атаку, можно перебросить Hit roll.")

export const overseer_of_redemption = new Ability("UNIT", "Overseer of Redemption",
  "",
  "Пока в этом юните есть Repentia Superior. Каждый раз когда модель этого юнита совершает атаку в Ближнем Бою, " +
  "можно перебросить Hit roll. Если этот юнит наступал в этом ходу, можно перебросить и Wound roll.")

export const feel_no_pain = new Ability("CORE", "Feel No Pain", "",
  "Каждый раз, когда модель с этой способносью получает рану (включая mortal wounds), брось D6: " +
  "если результат >= X, рана игнорируется.")

export const deadly_demise = new Ability("CORE", "Deadly Demise", "",
  "Когда такая модель уничтожается, брось D6 (если это TRANSPORT, до высадки моделей). Если выпало 6, каждый " +
  "юнит в пределах 6\" от этой модели получает X mortal wounds")

export const firing_deck = new Ability("CORE", "Firing Deck", "",
  "Каждый раз, когда такая модель выбрана для стрельбы в фазу стрельбы, можно выбрать до X моделей пассажиров. " +
  "Для каждой из них выбирается одно из экипированных ими Ranged оружий. До окончания розыгрыша атак транспорта, " +
  "считается, что он экипирован выбранными оружиями помимо его прочих оружий.")

export const rhino_transport = new Ability("CORE", "Transport", "",
  "This model has a transport capacity of 12 Adepta Sororitas Infantry models. It cannot transport Jump\n" +
  "Pack models.")

export const dead_ard = new Ability("UNIT", "Dead ’ard", "",
  "Во время Waaagh!, эта модель получает способность Feel No Pain 4+")

export const pistol = new Ability("WEAPON", "PISTOL", "",
  "В фазу стрельбы юнит может стрелять из пистолетов даже находясь на дистанции ближнего боя с противником, однако в этом" +
  " случае целью можно выбрать только вражеский юнит, находящийся на дистанции ближнего боя. Стрельба из пистолетов исключает " +
  "стрельбу из любого не-пистолетного оружия в юните (если юнит не Monster или Vehicle)")
export const rapid_fire = new Ability("WEAPON", "RAPID FIRE", "",
  "Увеличиет количество атак на X когда цель находится в пределах половины Range оружия")
export const hazardous = new Ability("WEAPON", "RAPID FIRE", "",
  "После розыграша атак юнита брось по одному D6 за каждое такое использованное оружие. За каждую выпавшую 1 " +
  " модель, вооруженная этим оружием уничтожается. Vehicles, Monsters, Characters вместо уничтожения получают 3 mortal wounds.")
export const ignores_cover = new Ability("WEAPON", "IGNORES COVER", "",
  "Когда соверашается атака таким оружием, цель не получает преимущество от укрытия")
export const torrent = new Ability("WEAPON", "TORRENT", "",
  "Атака таким оружием всегда попадает в цель (Hit roll не требуется).")
export const anti = new Ability("WEAPON", "ANTI", "",
  "Если у цели есть keyword, совпадающий с типом после anti, то все результаты Wound roll  >= X считаются критически успешными.")
export const sustained_hits = new Ability("WEAPON", "SUSTAINED HITS", "",
  "Каждое критическое попадание (немодифицированная 6 на Hit roll), увеличивает количество попаданий на X")
export const twin_linked = new Ability("WEAPON", "TWIN-LINKED", "",
  "Для каждой атаки из этого оружия можно перебросить Wound roll")
export const assault = new Ability("WEAPON", "ASSAULT", "",
  "Из этого оружия можно стрелять даже если юнит делал Advance в фазу движения")


class RuleRepository{
  static cache = {}
  static async get(name, unit_name = null) {
    if (!RuleRepository[name])
      RuleRepository.cache[name] = await query_rule(name)
    return RuleRepository.cache[name]
  }
}

export default RuleRepository