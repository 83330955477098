import "./Stratagems.css"
import {useEffect, useState} from "react";
import StratagemsRepository from "../repository/stratagems";
import {phaseIcons} from "../repository/pictures";

export default function StratagemsView(){
  const [stratagems, setStratagems] = useState([])
  useEffect(() => {
    StratagemsRepository.list().then(r => setStratagems(r))
  }, []);

  const sections = {};
  for (const strat of stratagems){
    const src = strat.source
    if (! sections[src]){
      sections[src] = []
    }
    sections[src].push(strat)
  }

  return (
    <div>
      {sections && Object.keys(sections).map(name => <StratagemSection name={name} stratagems={sections[name]}/>)}
    </div>
  )
}

export function StratagemSection(props){
  return (
    <div>
      <h3>{props.name}</h3>
      {props.stratagems.map(s=> <Stratagem stratagem={s}/>)}
    </div>
  )
}

export function Stratagem(props){
  const [collapsed, setCollapsed] = useState(true);

  const onHideClick = () =>{
    setCollapsed(!collapsed)
  }

  const phases = props.stratagem.phases.length === 0 ? ["ANY"] : props.stratagem.phases
  const turnClass = `${props.stratagem.turn}Turn`

  const s = props.stratagem
  return (
    <div className={`stratagemCard ${turnClass}`}>
      <div className="stratagemHeader" onClick={onHideClick}>
        <div className="stratagemIcons">{phases.map( p=> <img src={phaseIcons[p]}/>)} </div>
        <h2>{s.name} </h2>
        <div className="stratagemCost"> <h2>{s.cost}CP</h2></div>
      </div>
      <div className={`stratagemBody ${turnClass}` + (collapsed ? " hidden" : "")}>
        {s.when && <p><b>When:</b> {s.when}</p>}
        {s.target && <p><b>Target:</b> {s.target}</p>}
        {s.effect && <p><b>Effect:</b> {s.effect}</p>}
        {s.restrictions && <p><b>Restrictions:</b> {s.restrictions}</p>}
      </div>
    </div>
  )
}
