import "./ViewHeader.css"

export default function ViewHeader (props) {
  return (
    <div className="ViewHeader">
      <LeftButton onClick={props.onLeftClick} direction="left"/>
      <h1>{props.name}</h1>
      <LeftButton onClick={props.onRightClick} direction="right"/>
    </div>
  )
}

function LeftButton (props) {
  return (
    <div class={`arrow ${props.direction}`} onClick={props.onClick}>
      {props.direction === "right" ? ">>>" : "<<<"}
    </div>
  )
}