import './App.css';

import {orks_faction, sororitas_faction} from "./repository/units"
import {useEffect, useState} from 'react';
import RosterBuilder from "./components/RosterBuilder";
import BattleDatasheetsView from "./components/BattleDatasheetsView";
import Stratagems from "./components/Stratagems";
import {useSwipeable} from "react-swipeable";
import ViewHeader from "./components/ViewHeader";



function App() {
  const [rosters, setRosters] =  useState({})
  const [factions, _] = useState([sororitas_faction, orks_faction])

  const [currentView, setCurrentView] = useState("roster_selection")

  const viewNames = {
    "battle_datasheets": "DATASHEETS",
    "stratagems": "STRATAGEMS"
  }


  const onRosterComplete = (faction, roster) => {
    const new_rosters = Object.assign({}, rosters)
    new_rosters[faction] = roster
    setRosters(new_rosters)
  }

  const onViewChange = () => {
    if (currentView === "battle_datasheets")
      setCurrentView('stratagems')
    if (currentView === "stratagems")
      setCurrentView('battle_datasheets')
  }


  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      onViewChange()
    }
  });


  if ( Object.keys(rosters).length < 2  && currentView !== "roster_selection") {
    setCurrentView("roster_selection")
  }
  if ( Object.keys(rosters).length >= 2  && currentView === "roster_selection") {
    setCurrentView("battle_datasheets")
  }

  const pendingFactions = factions.filter(f => !rosters[f])

  return (
    <div className="App">
      {currentView !== 'roster_selection' &&
        <ViewHeader name={viewNames[currentView]} onLeftClick={onViewChange} onRightClick={onViewChange}/>
      }

      <div className="ViewBody">
        {currentView === 'roster_selection' && <RosterBuilder key={pendingFactions[0]} faction={pendingFactions[0]} onSubmit={onRosterComplete}/>}
        {currentView === 'battle_datasheets' && <BattleDatasheetsView rosters={rosters}/>}
        {currentView === 'stratagems' && <Stratagems  />}
      </div>
    </div>
  )
}

export default App;
