import './BattleDatasheetsView.css';
import {orks_faction, sororitas_faction} from "../repository/units"
import Datasheet from "./Datasheet";
import UnitList from "./UnitList";
import {useEffect, useState} from 'react';


export default function BattleDatasheetsView(props) {
  const rosters = props.rosters
  const [factions, _] = useState([sororitas_faction, orks_faction])

  const [selectedUnits, setSelectedUnits] = useState([]);

  let display_units = []

  for (const unit_id of selectedUnits)
    for (const f of factions) {
      const unit = rosters[f].get_unit(unit_id)
      const _ = unit ? display_units.push(unit) : null
    }

  return (
    <div className="App">
      {rosters[sororitas_faction] &&
        <div className="top sororitas">
          <UnitList units={rosters[sororitas_faction].list_units()} faction={sororitas_faction} onSelect={setSelectedUnits} selectedUnits={selectedUnits} roster={rosters[sororitas_faction]}/>
        </div>
      }
      <div className="body">
        {rosters && display_units.map(unit => <Datasheet unit={unit}/>)}
      </div>
      {rosters[orks_faction] &&
        <div className="bottom orks">
          <UnitList units={rosters[orks_faction].list_units()} faction={orks_faction} onSelect={setSelectedUnits} selectedUnits={selectedUnits} roster={rosters[orks_faction]}/>
        </div>
      }
    </div>
  );
}

