import "./Datasheet.css"
import "./UnitList.css"

import { factions } from "../repository/pictures"

export default function UnitList(props){
  const faction = props.faction
  const faction_picture = factions[faction]
  const roster = props.roster
  const all_unit_ids = roster.list_units().map(u => u.id)

  const onIconClick = (unit_id) => {
    if (props.selectedUnits.includes(unit_id))
      props.onSelect([...props.selectedUnits.filter(u => u !== unit_id)])
    else
      props.onSelect([...props.selectedUnits, unit_id])
    console.log("selected", props.selectedUnits)

  }

  const onClickAll = (faction_id) => {
    const selected_faction_units = props.selectedUnits.filter(u => roster.get_unit(u))
    if (selected_faction_units.length > 0)
      props.onSelect([...props.selectedUnits.filter(unit_id => !all_unit_ids.includes(unit_id))])
    else
      props.onSelect([...props.selectedUnits, ...all_unit_ids])
  }

  return (
    <div className="UnitList">
      <UnitIcon picture={faction_picture} id={props.units[0].faction} onClick={onClickAll} name="ALL"
                isSelected={all_unit_ids.some(uid => props.selectedUnits.includes(uid))}/>
      {props.units.map(u => <UnitIcon picture={u.picture} id={u.id} name={u.name} onClick={onIconClick}
                                      isSelected={props.selectedUnits.includes(u.id)}/>)}
    </div>
  )
}


function UnitIcon(props){
  let styles = "UnitListPicture"
  if (props.isSelected)
    styles += " Highlighted"
  return (
    <div className="UnitListIcon" onClick={() => props.onClick(props.id)} id={props.id}>
      <img className={styles} src={props.picture} alt={props.name}/>
    </div>
  );
}
