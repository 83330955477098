import {units_repo} from "./units";


class Roster{
  units = {}

  add_unit(unit){
    this.units[unit.id] = unit
  }

  get_unit(unit_id){
    return this.units[unit_id]
  }

  list_units(){
    return Object.values(this.units).filter(u => ! u.leader)
  }

  delete_unit(unit_id){
    delete this.units[unit_id]
    this.fix_roster()
  }

  update_unit(new_unit){
    this.units[new_unit.id] = new_unit
    this.fix_roster()
  }

  fix_roster(){
    for (const unit of Object.values(this.units)){
      if (unit.leader && this.get_unit(unit.leader.id) === undefined)
        unit.leader = null
      if (unit.bodyguard && this.get_unit(unit.bodyguard.id) === undefined)
        unit.bodyguard = null
    }
  }

  assign_leader(leader_id, bodyguard_id){
    const leader = this.get_unit(leader_id)
    if (leader.bodyguard) {
      const current_bodyguard = this.get_unit(leader.bodyguard.id)
      current_bodyguard.leader = undefined
    }

    const bodyguard = this.get_unit(bodyguard_id)
    leader.bodyguard = bodyguard
    this.update_unit(leader)
    if (bodyguard) {
      bodyguard.leader = leader
      this.update_unit(bodyguard)
    }
    this.fix_roster()
  }
}

export class RostersRepo{
  static async get(faction_name){
    const r = new Roster();
    await units_repo.get_all_templates(faction_name)
      .then(templates =>
        templates.forEach(t => r.add_unit(t.copy()))
      )
    return r
  }
}
