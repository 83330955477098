import "./Datasheet.css"
import { Tooltip } from 'react-tooltip'
import RuleRepository from "../repository/abilities";
import {useEffect, useState} from "react";

export default function Datasheet(props){
  const faction_style = props.unit.faction === "ORKS" ? "orks": "sororitas"
  return (
    <div key={props.unit.id} className={"UnitDatasheet "+ faction_style}>
      <div className="datasheetHeader">
        <div>
          <header className="datasheetName"> {props.unit.name} </header>
          {props.unit.leader && <p className="datasheetLeaderName"> Leader: {props.unit.leader.name} </p>}
          <UnitProfile models={props.unit.models}/>
          <p className="dsUnitKeywords"><b>Keywords:</b> {props.unit.keywords.join(', ')}</p>
        </div>
        {props.unit.picture && <img className="datasheetPic" src={props.unit.picture} alt=""/>}
      </div>
      <AbilityList abilities={props.unit.abilities}/>
      <WeaponsList unit={props.unit}/>
      {props.unit.bodyguard && <Datasheet unit={props.unit.bodyguard}/>}
    </div>
  );
}


function UnitProfile(props){
  const keys = ["m", "t", "sv", 'inv', "w", "ld", "oc"]
  return(
    <div className="datasheetStats">
      <table>
        <thead><tr>{keys.map(key => <th> {key} </th>)}</tr></thead>
        <tbody>{props.models.map( model => <ModelStats keys={keys} model={model} doShowName={props.models.length > 1}/>)}</tbody>
      </table>
    </div>
  )
}

function ModelStats(props){
  return (
    <tr>
      {props.keys.map( key =>
        <td>
          <div className="dsCharFrame">{props.model[key]} </div>
        </td>
      )}
      {props.doShowName && <td>{props.model.name}</td>}
    </tr>
  )
}


function WeaponsList(props){
  const ranged_keys = ["name", "range", "a", "bs", "s", "ap", "d"]
  const melee_keys = ["name", "range", "a", "ws", "s", "ap", "d"]

  const ranged_headers = ["RANGED", "range",  "a", "bs", "s", "ap", "d"]
  const melee_headers = ["MELEE",  "", "a", "ws", "s", "ap", "d"]

  const melee_weapons = props.unit.melee_weapons
  const ranged_weapons = props.unit.ranged_weapons

  return(
    <div>
      <table className="dsWeaponTable">
        {ranged_weapons.length > 0 && <thead ><tr>{ranged_headers.map(key => <th className="dsWeaponHeader"> {key} </th>)}</tr></thead>}
        <tbody>{ranged_weapons.map( weapon => <WeaponStats keys={ranged_keys} weapon={weapon}/>)}</tbody>
        {melee_weapons.length > 0 && <thead><tr>{melee_headers.map(key => <th className="dsWeaponHeader"> {key} </th>)}</tr></thead>}
        <tbody>{melee_weapons.map( weapon => <WeaponStats keys={melee_keys} weapon={weapon}/>)}</tbody>
      </table>
    </div>
  )
}

function WeaponAbility(props){
  const a = props.ability
  const tooltip_id = `weapon_kw_tooltip-${a.name}`
  return(
    <div className="dsWeaponKeywordTooltip">
      <a  data-tooltip-id={tooltip_id} data-tooltip-place="top"
         data-tooltip-content={a.description}>
        <Tooltip style={{width: "90%", fontSize: "var(--normal-font-size)"}} id={tooltip_id} />
        &nbsp;[<span>{a.name}{a.parameter && ` ${a.parameter}`}</span>]
      </a>
    </div>
  )
}


function WeaponStats(props){

  return (
    <tr className="dsWeaponRow">
      {props.keys.map( key =>
        key==='name' ?
          <td className="dsWeaponCell">
            <div className="dsWeaponCellContent">
              <div className="dsWeaponName"> {props.weapon[key]} </div>
              {props.weapon.abilities && props.weapon.abilities.map(kw => <WeaponAbility ability={kw}/>)}
            </div>
          </td>
          :
          <td className="dsWeaponCell">
            <div className="dsWeaponName"> {props.weapon[key]} </div>
            </td>


      )}
    </tr>
  )
}


function AbilityList(props){
  const core_abilities = props.abilities.filter(a => !a.type || a.type === "CORE")
  const faction_abilities = props.abilities.filter(a => a.type === "FACTION")
  const unit_abilities = props.abilities.filter(a => a.type === "UNIT_ABILITY")
  return (
    <div className="dsAbilities">
      <div className="dsHorAbilityList">
        {core_abilities.map(a => <AbilityTooltip ability={a}/>)}
      </div>
      <div className="dsHorAbilityList">
        {faction_abilities.map(a => <AbilityTooltip ability={a}/>)}
      </div>
      {unit_abilities.map(a =>
        <div className="dsAbilityCard">
          <p className="dsAbilityHeader">{a.name}{a.parameter && ` ${a.parameter}`}:</p>
          <p className="dsAbilityBody">
            {a.condition && <i>{a.condition}</i>}
            {a.condition && <br/>}
            {a.description}
          </p>
        </div>
      )}
    </div>
  )
}

function AbilityTooltip(props){
  let placeholder = props.ability.name ? props.ability : {"name": props.ability}

  const [ability, setAbility] = useState(placeholder)
  useEffect(() => {
    if (!ability.description) {
      RuleRepository.get(placeholder.name).then( r =>
      {
        setAbility(r)
        console.log("loded ability", r)}
      )
    }
  }, []);


  return(
    <div className="dsAbilityTooltip">
      <a data-tooltip-id="my-tooltip" data-tooltip-place="top"
         data-tooltip-html={ability.description ? `${ability.description.replaceAll('\\n', '<br/>')}`: ""}>
        <p className="dsAbilityHeader"> {ability.name}{ability.parameter && ` ${ability.parameter}`}</p>
      </a>
      <Tooltip style={{width: "90%", fontSize: "var(--normal-font-size)"}} id="my-tooltip" />
    </div>
  )
}


