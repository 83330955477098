import {useEffect, useState} from "react";
import {RostersRepo} from "../repository/cp_rosters";
import Datasheet from "./Datasheet";
import RuleRepository from "../repository/abilities";
import {orks_faction, sororitas_faction, units_repo} from "../repository/units";


const wargear_options = {
  "canoness": ["Armour of Faith", "Saintly Relic"],
  "warboss_in_mega_armor": ["Grizzled Scarboy", "Tellyporta"]
}

function make_bss_parts(){
  const default_unit = units_repo.get("battle_sisters_squad")
  const p1 = default_unit.copy()
  const p2 = default_unit.copy()
  p1.ranged_weapons = p1.ranged_weapons.filter(w => w.name !== "Ministorum flamer")
  p1.melee_weapons = p2.melee_weapons.filter(w => w.name !== "Chainsword")
  p1.id = `${p1.id}_1`
  p1.name = `BSS WITH STORM BOLTER`
  p2.ranged_weapons = p2.ranged_weapons.filter(w => w.name !== "Artificer-crafted storm bolter")
  p2.id = `${p2.id}_2`
  p2.name = `BSS WITH FLAMER`
  return [p1, p2]
}


function make_ork_boyz(roster){
  const default_unit = units_repo.get("boyz")
  if (default_unit) {
    const p1 = default_unit.copy()
    const p2 = default_unit.copy()
    p1.ranged_weapons = p1.ranged_weapons.filter(w => w.name !== "Rokkit launcha")
    p1.melee_weapons = p1.melee_weapons.filter(w => w.name !== "Power klaw")
    p1.id = `${p1.id}_big_shoota`
    p1.name = `Boyz with Big Shoota`

    p2.ranged_weapons = p2.ranged_weapons.filter(w => w.name !== "Big shoota")
    p2.melee_weapons = p2.melee_weapons.filter(w => w.name !== "Big choppa")
    p2.id = `${p2.id}_rokkits`
    p2.name = `Boys with Rokkits`

    roster.delete_unit(default_unit.id)
    roster.add_unit(p1)
    roster.add_unit(p2)
  }
  return true
}

export default function RosterBuilder(props) {
  const [roster, setRoster] =  useState(undefined)
  const [units, setUnits] = useState([])
  const [splitUnitParts, setSplitUnitParts] = useState([])

  const uploadRosters = async  () => {
    const r = await RostersRepo.get(props.faction)
    setRoster(r)
  }

  useEffect( () => {
    uploadRosters()
  }, [])

  const onAbilitySelect= (unit_id, selectedAbility, otherAbilities) =>{
    const unit = roster.get_unit(unit_id)
    unit.abilities = unit.abilities.filter(a => ! otherAbilities.includes(a.name))
    RuleRepository.get(selectedAbility).then( r => {
      unit.abilities.push(r)
      roster.update_unit(unit)
      setUnits(roster.list_units())
    })
  }

  const onSplit = (unit_id, do_split) =>{
    const default_unit = units_repo.get(unit_id)
    if (do_split === "true"){
      const parts = make_bss_parts()
      parts.forEach(p => roster.add_unit(p))
      roster.delete_unit(default_unit.id)
      setSplitUnitParts([...splitUnitParts, ...parts])
    }
    else{
      for (const unit_part of splitUnitParts)
        roster.delete_unit(unit_part.id)
      roster.add_unit(default_unit)
      setSplitUnitParts([])
    }
  }

  const onLeaderAttached = (leader_id, bodyguard_id) =>{
    roster.assign_leader(leader_id, bodyguard_id)
    setUnits(roster.list_units())
  }

  const onSubmit = () => {
    props.onSubmit(props.faction, roster)
  }

  if (roster)
    return(
      <div>
        <div>
          {props.faction === sororitas_faction &&
            <div>
              <AbilityOption unit={roster.get_unit("canoness_ellyrine")} options={["Armour of Faith", "Saintly Relic"]} onSelect={onAbilitySelect}/>
              <UnitSplitDecision unit={units_repo.get("battle_sisters_squad")} onSelect={onSplit}/>
              <AttachDecision leader={roster.get_unit("canoness_ellyrine")}
                              options={splitUnitParts.length > 0 ? splitUnitParts : [roster.get_unit("battle_sisters_squad")]}
                              onSelect={onLeaderAttached}
              />
            </div>
         }
          {props.faction === orks_faction && make_ork_boyz(roster) &&
            <div>
              <AbilityOption unit={roster.get_unit("warboss_gordrang")} options={["Grizzled Skarboy", "Tellyporta"]} onSelect={onAbilitySelect}/>
            </div>
          }
          <button onClick={onSubmit}>DONE</button>
        </div>
        <div>
          {roster.list_units().map(unit => <Datasheet unit={unit}/>)}
        </div>
      </div>
    )
  else
    return <p>Loading...</p>
}

function AbilityOption(props){
  return (
    <div>
      <p>Выбери способность для {props.unit.name}:
        <select onChange={e => props.onSelect(props.unit.id, e.target.value, props.options)}>
          {props.options.map(o => <option value={o}>{o}</option>)}
        </select>
      </p>
    </div>
  )
}

function UnitSplitDecision(props){
  return (
    <div>
      <p>Разделить юнит {props.unit.name}:
        <select onChange={e => props.onSelect(props.unit.id, e.target.value)}>
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </p>
    </div>
  )
}


function AttachDecision(props){
  return (
    <div>
      <p>Присоединить лидера {props.leader.name} к отряду:
        <select onChange={e => props.onSelect(props.leader.id, e.target.value)}>
          <option selected={!props.leader.bodyguard} value={null}>_</option>
          {props.options.map(o => <option selected={props.leader.bodyguard && props.leader.bodyguard.id === o.id} value={o.id}>{o.name}</option>)}
        </select>
      </p>
    </div>
  )
}
