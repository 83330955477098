import axios from "axios";

const BASE_URL = "/api"

export async function query_rule(name, unit_name = null) {
  const res = await axios.get(BASE_URL + '/rules', {params: {rule_name: name}})
  return res.data;
}

export async function query_unit_template(name) {
  const res = await axios.get(BASE_URL + '/unit_templates', {params: {unit_template_name: name}})
  return res.data;
}

export async function stratagems(name= undefined, source = undefined) {

  const res = await axios.get(BASE_URL + '/stratagems', {params: {name: name, source: source}})
  return res.data;
}
