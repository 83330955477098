export const factions = {
  "ADEPTA SORORITAS": "./army_pics/sororitas_logo.png",
  "ORKS": "./army_pics/orks_logo.png",

  'Canoness Ellyrine': "./army_pics/canoness.png",
  "BATTLE SISTERS SQUAD":  "./army_pics/bss.png",
  "SERAPHIM SQUAD":  "./army_pics/seraphim.png",
  "REPENTIA SQUAD": "./army_pics/repentia.png",
  "ARCO-FLAGELLANTS": "./army_pics/flagellant.png",
  "PENITENT ENGINES": "./army_pics/penitent.png",
  "SORORITAS RHINO": "./army_pics/rhino.png",
  "Warboss Gordrang": "./army_pics/warboss_gordrang.png",
  "BOYZ": "./army_pics/ork_boyz.png",
  "DEFFKOPTAS": "./army_pics/deffkoptas.png",
  "DEFF DREAD": "./army_pics/deff_dread.png"
}

export const phaseIcons = {
  "ANY": "./phase_icons/stratAny.png",
  "COMMAND": "./phase_icons/stratCommand.png",
  "MOVEMENT": "./phase_icons/stratMovement.png",
  "SHOOTING": "./phase_icons/stratShooting.png",
  "CHARGE": "./phase_icons/stratCharge.png",
  "FIGHT": "./phase_icons/stratFight.png"
}